import React from "react"
import styled from "styled-components"

export type ButtonProps = {
  children?: HTMLCollection | string
  onClick?: (e?: React.MouseEvent) => void
  link?: string
  themeStyle?: "neutral" | "primary"
  color?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const themeStyleMap = {
  NEUTRAL: "neutral",
  PRIMARY: "primary",
}

const Button = ({
  onClick,
  children,
  themeStyle,
  link,
  color,
  ...otherProps
}: ButtonProps) => {
  const content = (
    <ButtonComponent
      onClick={onClick}
      themeStyle={themeStyle}
      color={color}
      {...otherProps}
    >
      {children}
    </ButtonComponent>
  )

  if (link) {
    return (
      <a href={link} rel="noreferrer noopener" target="_blank">
        {content}
      </a>
    )
  }

  return content
}

export { Button }

const ButtonComponent = styled.button<ButtonProps>`
  padding: ${props => `${props.theme.paddings.p2} ${props.theme.paddings.p4}`};
  color: ${props =>
    props.themeStyle === themeStyleMap.PRIMARY
      ? "white"
      : props.color || props.theme.colors.primary};
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  border-radius: 3px;
  appearance: none;
  cursor: pointer;
  transition: background-color 0.1s linear, color 0.1s linear;
  border: 1px solid ${props => props.color || props.theme.colors.primary};
  border-radius: 0.25rem;
  background-color: ${props =>
    props.themeStyle === themeStyleMap.PRIMARY
      ? props.color || props.theme.colors.primary
      : "transparent"};
  &:hover {
    background-color: ${props =>
      props.themeStyle === themeStyleMap.PRIMARY
        ? props.theme.colors.primarydark
        : props.color || props.theme.colors.primary};
    color: white;
  }
`
