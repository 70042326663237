import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import "src/markdown.css"

import Layout from "components/shared/layout"

export default function Template({ data }: { data: any }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <Container>
        <Title>{frontmatter.title}</Title>
        <SubTitle>Mis à jour le : {frontmatter.date}</SubTitle>
        <Body>
          <div className="markdown">
            <div className="legal-container">
              <div className="legal">
                <div
                  className="legal-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
          </div>
        </Body>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        slug
        title
      }
    }
  }
`

const Container = styled.footer`
  margin: 0 auto;
  padding: 0 ${props => props.theme.paddings.p6};
  max-width: 900px;
`

const Title = styled.h1`
  margin: ${props => props.theme.margins.m32} 0
    ${props => props.theme.margins.m4} 0;
`
const SubTitle = styled.p`
  margin: 0 0 ${props => props.theme.margins.m5} 0;
`

const Body = styled.footer`
  margin: 0 auto;
  padding: 0 ${props => props.theme.paddings.p6};
  max-width: 600px;
`
